<template>
    <v-dialog v-model="dialog" :max-width="720" persistent scrollable>
        <v-card v-if="person && condition">
            <v-toolbar dense dark color="primary">
                <v-toolbar-title>{{ $t(`labels.${mode}Condition`) }}</v-toolbar-title>
                <v-spacer></v-spacer>
                <v-btn small text outlined class="link-btn btn-outline-solid-white" @click="$root.$medlinePlus.show(condition)" v-if="hasSnomed(condition)">
                    {{ $t('labels.medlinePlusInfo') }}
                    <v-icon small right>fas fa-info-circle</v-icon>
                </v-btn>
            </v-toolbar>
            <div class="text-h6 pa-5 text-center primary--text">{{ $t(`conditions.${condition.id}.name`) }}</div>
            <div class="text-subtitle-1 mt-n5 hint-text text-center">{{ $t(`conditions.${condition.id}.hint`) }}</div>
            <v-divider class="mt-5"></v-divider>
            <v-card-text class="pa-0">
                <div class="mx-12 my-3 pa-6 text-center" v-if="!condition.skipAge">
                    <v-row no-gutters class="mb-4 mt-n4">
                        <v-col cols="8" offset="2">
                            <div class="text-subtitle-1 text-center primary--text">{{ $t(condition.ageLabel || 'fields.onsetAge') }}</div>
                        </v-col>
                        <v-col cols="2" class="text-right">
                            <v-checkbox class="my-0 mx-2" dense hide-details v-model="condition.ageUnknown" :label="$t('common.unknown')" @change="ageUnknownChange"></v-checkbox>
                        </v-col>
                    </v-row>
                    <AgeRange v-model="condition.age" :max="age"></AgeRange>
                    <div class="d-flex align-center mt-4 mb-n4">
                        <v-spacer></v-spacer>
                        <div class="text-subtitle-1 hint-text mr-1">{{ $t('hints.ageSlider') }}</div>
                        <Hint :text="$t('hints.ageSliderMore')" :title="$t('fields.onsetAge')"></Hint>
                        <v-spacer></v-spacer>
                    </div>
                </div>
                <v-divider></v-divider>
                <template v-for="(prop, index) in metaProps">
                    <div class="my-3" :key="prop">
                        <div class="text-subtitle-1 text-center primary--text">
                            <span v-if="['numberOfCancers', 'numberOfSkinCancersMelanoma', 'numberOfSkinCancersNonMelanoma'].includes(prop)">{{ $t(`fields.${prop}.${'SELF' === person.relation ? 'self' : 'relative'}`) }}</span>
                            <span v-else>{{ $t(`fields.${prop}`) }}</span>
                            <v-tooltip top max-width="680" v-if="'cancerGrade' === prop">
                                <template #activator="{ on, attrs }">
                                    <v-icon small color="primary" v-bind="attrs" v-on="on">fas fa-info-circle</v-icon>
                                </template>
                                <span>{{ $t('hints.cancerGrade') }}</span>
                            </v-tooltip>
                        </div>
                        <v-row no-gutters justify="center" v-if="'other' === prop">
                            <v-col class="mx-12">
                                <v-text-field
                                    dense
                                    outlined
                                    class="ma-0 mb-2"
                                    :hint="isOtherCondition ? $t('prompts.otherConditionWarning') : ''"
                                    :persistent-hint="isOtherCondition"
                                    :hide-details="!isOtherCondition"
                                    type="text"
                                    v-model="condition.meta.other"
                                ></v-text-field>
                            </v-col>
                        </v-row>
                        <div v-if="'other' === prop && searchText && person && filteredConditions && filteredConditions.length">
                            <div class="text-subtitle-1 text-center primary--text"><em v-html="$t('prompts.otherConditionMatches')"></em></div>
                            <div class="d-flex align-center justify-space-between mx-6">
                                <div class="text-subtitle-1 hint-text">{{ $t('hints.addConditionsMatch') }}</div>
                                <div class="text-caption hint-text">{{ $tc('common.results', filteredConditions.length) }}</div>
                            </div>
                            <v-divider></v-divider>
                            <v-card-text class="pa-0" style="height: 416px">
                                <ConditionsList
                                    :person="person"
                                    :familyConditions="familyConditions"
                                    :filteredConditions="filteredConditions"
                                    @click="
                                        (cond) =>
                                            cond.skipQuestions &&
                                            $root.$confirmationDialog
                                                .show({ title: $t('prompts.conditionAdded.title'), message: $t('prompts.conditionAdded.message', { condition: $t(`conditions.${cond.id}.name`) }), showCancel: false })
                                                .finally(cancel)
                                    "
                                ></ConditionsList>
                            </v-card-text>
                        </div>
                        <v-row no-gutters justify="center" v-if="'maxHeight' === prop">
                            <v-col cols="4">
                                <HeightField v-model="condition.meta.maxHeight"></HeightField>
                            </v-col>
                        </v-row>
                        <v-row no-gutters justify="center" v-if="'maxWeight' === prop">
                            <v-col cols="4">
                                <WeightField v-model="condition.meta.maxWeight"></WeightField>
                            </v-col>
                        </v-row>
                        <div class="d-flex justify-center flex-wrap mx-12 px-6" v-if="'cancerTypes' === prop || 'dvtTypes' === prop">
                            <v-checkbox v-for="type in condition.meta[prop]" :key="type.id" class="my-0 mx-2" hide-details v-model="type.selected" :label="$t(`conditions.${condition.id}.${type.id}`)"></v-checkbox>
                        </div>
                        <div class="d-flex flex-column mx-12 px-6" v-if="'bloodClotRisks' === prop">
                            <div class="text-caption mt-n2 hint-text text-center">{{ $t('hints.checkAll') }}</div>
                            <v-checkbox v-for="(value, index) in bloodClotRisks" :key="index" class="ma-0" hide-details v-model="condition.meta.bloodClotRisks" :label="$t(`bloodClotRisks.${value}`)" :value="value"></v-checkbox>
                        </div>
                        <div class="d-flex justify-center" v-if="'biopsies' === prop">
                            <v-checkbox
                                v-for="(value, index) in biopsies"
                                :key="index"
                                class="mt-0 mb-2 mx-2"
                                hide-details
                                v-model="condition.meta.biopsies"
                                :label="valueLabel(value)"
                                :value="value"
                                on-icon="$radioOn"
                                off-icon="$radioOff"
                            ></v-checkbox>
                        </div>
                        <div class="d-flex justify-center" v-if="'biopsyResult' === prop">
                            <v-checkbox
                                v-for="(value, index) in biopsyResults"
                                :key="index"
                                class="mt-0 mb-2 mx-2"
                                hide-details
                                v-model="condition.meta.biopsyResult"
                                :label="$t(`biopsyResults.${value}`)"
                                :value="value"
                                on-icon="$radioOn"
                                off-icon="$radioOff"
                            ></v-checkbox>
                        </div>
                        <div class="d-flex justify-center" v-if="'bothBreasts' === prop">
                            <v-checkbox
                                v-for="(value, index) in bothBreasts"
                                :key="index"
                                class="mt-0 mb-2 mx-2"
                                hide-details
                                v-model="condition.meta.bothBreasts"
                                :label="valueLabel(value)"
                                :value="value"
                                on-icon="$radioOn"
                                off-icon="$radioOff"
                            ></v-checkbox>
                        </div>
                        <div class="d-flex justify-center" v-if="'cancerGrade' === prop">
                            <v-checkbox
                                v-for="(value, index) in cancerGrades"
                                :key="index"
                                class="mt-0 mb-2 mx-2"
                                hide-details
                                v-model="condition.meta.cancerGrade"
                                :label="valueLabel(value)"
                                :value="value"
                                on-icon="$radioOn"
                                off-icon="$radioOff"
                            ></v-checkbox>
                        </div>
                        <div class="d-flex justify-center" v-if="'erReceptorType' === prop">
                            <v-checkbox
                                v-for="(value, index) in erReceptorTypes"
                                :key="index"
                                class="mt-0 mb-2 mx-2"
                                hide-details
                                v-model="condition.meta.erReceptorType"
                                :label="valueLabel(value)"
                                :value="value"
                                on-icon="$radioOn"
                                off-icon="$radioOff"
                            ></v-checkbox>
                        </div>
                        <div class="d-flex justify-center" v-if="'prReceptorType' === prop">
                            <v-checkbox
                                v-for="(value, index) in prReceptorTypes"
                                :key="index"
                                class="mt-0 mb-2 mx-2"
                                hide-details
                                v-model="condition.meta.prReceptorType"
                                :label="valueLabel(value)"
                                :value="value"
                                on-icon="$radioOn"
                                off-icon="$radioOff"
                            ></v-checkbox>
                        </div>
                        <div class="d-flex justify-center" v-if="'her2ReceptorType' === prop">
                            <v-checkbox
                                v-for="(value, index) in her2ReceptorTypes"
                                :key="index"
                                class="mt-0 mb-2 mx-2"
                                hide-details
                                v-model="condition.meta.her2ReceptorType"
                                :label="valueLabel(value)"
                                :value="value"
                                on-icon="$radioOn"
                                off-icon="$radioOff"
                            ></v-checkbox>
                        </div>
                        <div class="d-flex justify-center" v-if="'numberOfMiscarriages' === prop">
                            <v-checkbox
                                v-for="(value, index) in numberOfMiscarriages"
                                :key="index"
                                class="mt-0 mb-2 mx-2"
                                hide-details
                                v-model="condition.meta.numberOfMiscarriages"
                                :label="valueLabel(value)"
                                :value="value"
                                on-icon="$radioOn"
                                off-icon="$radioOff"
                            ></v-checkbox>
                        </div>
                        <div class="d-flex justify-center" v-if="'numberOfCancers' === prop">
                            <v-checkbox
                                v-for="(value, index) in numberOfCancers"
                                :key="index"
                                class="mt-0 mb-2 mx-2"
                                hide-details
                                v-model="condition.meta.numberOfCancers"
                                :label="valueLabel(value)"
                                :value="value"
                                on-icon="$radioOn"
                                off-icon="$radioOff"
                            ></v-checkbox>
                        </div>
                        <div class="d-flex justify-center" v-if="'numberOfSkinCancersMelanoma' === prop">
                            <v-checkbox
                                v-for="(value, index) in numberOfSkinCancers"
                                :key="index"
                                class="mt-0 mb-2 mx-2"
                                hide-details
                                v-model="condition.meta.numberOfSkinCancersMelanoma"
                                :label="valueLabel(value)"
                                :value="value"
                                on-icon="$radioOn"
                                off-icon="$radioOff"
                            ></v-checkbox>
                        </div>
                        <div class="d-flex justify-center" v-if="'numberOfSkinCancersNonMelanoma' === prop">
                            <v-checkbox
                                v-for="(value, index) in numberOfSkinCancers"
                                :key="index"
                                class="mt-0 mb-2 mx-2"
                                hide-details
                                v-model="condition.meta.numberOfSkinCancersNonMelanoma"
                                :label="valueLabel(value)"
                                :value="value"
                                on-icon="$radioOn"
                                off-icon="$radioOff"
                            ></v-checkbox>
                        </div>
                        <div class="d-flex justify-center" v-if="'glands' === prop">
                            <v-checkbox
                                v-for="(value, index) in glands"
                                :key="index"
                                class="mt-0 mb-2 mx-2"
                                hide-details
                                v-model="condition.meta.glands"
                                :label="valueLabel(value)"
                                :value="value"
                                on-icon="$radioOn"
                                off-icon="$radioOff"
                            ></v-checkbox>
                        </div>
                        <div class="d-flex justify-center" v-if="'colonPolyps' === prop">
                            <v-checkbox
                                v-for="(value, index) in colonPolyps"
                                :key="index"
                                class="mt-0 mb-2 mx-2"
                                hide-details
                                v-model="condition.meta.colonPolyps"
                                :label="valueLabel(value)"
                                :value="value"
                                on-icon="$radioOn"
                                off-icon="$radioOff"
                            ></v-checkbox>
                        </div>
                        <div class="d-flex justify-center flex-wrap mx-3" v-if="'gleasonScore' === prop">
                            <v-checkbox
                                v-for="(value, index) in gleasonScores"
                                :key="index"
                                class="mt-0 mb-2 mx-2"
                                hide-details
                                v-model="condition.meta.gleasonScore"
                                :label="$t(`gleasonScores.${value}`)"
                                :value="value"
                                on-icon="$radioOn"
                                off-icon="$radioOff"
                            ></v-checkbox>
                        </div>
                        <div class="d-flex justify-center" v-if="'metastatic' === prop">
                            <v-checkbox
                                v-for="(value, index) in metastatic"
                                :key="index"
                                class="mt-0 mb-2 mx-2"
                                hide-details
                                v-model="condition.meta.metastatic"
                                :label="valueLabel(value)"
                                :value="value"
                                on-icon="$radioOn"
                                off-icon="$radioOff"
                            ></v-checkbox>
                        </div>
                        <div class="d-flex justify-center" v-if="'lipomas' === prop">
                            <v-checkbox
                                v-for="(value, index) in lipomas"
                                :key="index"
                                class="mt-0 mb-2 mx-2"
                                hide-details
                                v-model="condition.meta.lipomas"
                                :label="valueLabel(value)"
                                :value="value"
                                on-icon="$radioOn"
                                off-icon="$radioOff"
                            ></v-checkbox>
                        </div>
                        <div class="d-flex justify-center" v-if="'mismatchRepairDeficiency' === prop">
                            <v-checkbox
                                v-for="(value, index) in mismatchRepairDeficiencies"
                                :key="index"
                                class="mt-0 mb-2 mx-2"
                                hide-details
                                v-model="condition.meta.mismatchRepairDeficiency"
                                :label="valueLabel(value)"
                                :value="value"
                                on-icon="$radioOn"
                                off-icon="$radioOff"
                            ></v-checkbox>
                        </div>
                    </div>
                    <v-divider :key="index"></v-divider>
                </template>
                <div
                    class="text-subtitle-1 hint-text text-center my-2"
                    v-if="hasMeta(condition, 'erReceptorType') && hasMeta(condition, 'prReceptorType') && hasMeta(condition, 'her2ReceptorType')"
                    v-html="$t('hints.tripleNegativeBreastCancer')"
                ></div>
            </v-card-text>
            <v-card-actions>
                <v-checkbox v-model="causeOfDeath" :label="$t('fields.conditionCauseOfDeath')" hide-details class="mx-3 my-0 pa-0" v-if="'Deceased' === person.living"></v-checkbox>
                <v-spacer></v-spacer>
                <v-btn text color="primary" @click="ok()" :disabled="!isValid">
                    <v-icon left>fas fa-check</v-icon>
                    {{ $t('common.ok') }}
                </v-btn>
                <v-btn text @click="cancel()">
                    <v-icon left>fas fa-times</v-icon>
                    {{ $t('common.cancel') }}
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
    import AgeRange from '@/components/AgeRange';
    import { ConditionsMixin } from '@/components/ConditionsMixin';
    import { ConditionsSearchMixin } from '@/components/ConditionsSearchMixin';
    import ConditionsList from '@/components/ConditionsList';
    import HeightField from '@/components/HeightField';
    import WeightField from '@/components/WeightField';
    import {
        optionalMetaProps,
        bloodClotRisks,
        biopsies,
        biopsyResults,
        bothBreasts,
        cancerGrades,
        erReceptorTypes,
        prReceptorTypes,
        her2ReceptorTypes,
        numberOfMiscarriages,
        numberOfCancers,
        numberOfSkinCancers,
        glands,
        colonPolyps,
        gleasonScores,
        metastatic,
        lipomas,
        mismatchRepairDeficiencies
    } from '@/common';
    import { debounce, includes, isNumber } from 'lodash';
    import moment from 'moment';

    export default {
        mixins: [ConditionsMixin, ConditionsSearchMixin],
        components: { AgeRange, ConditionsList, HeightField, WeightField },
        data: () => ({
            bloodClotRisks,
            bothBreasts,
            biopsies,
            biopsyResults,
            cancerGrades,
            erReceptorTypes,
            prReceptorTypes,
            her2ReceptorTypes,
            numberOfMiscarriages,
            numberOfCancers,
            numberOfSkinCancers,
            glands,
            colonPolyps,
            gleasonScores,
            metastatic,
            lipomas,
            mismatchRepairDeficiencies,
            causeOfDeath: false,
            mode: null,
            dialog: false,
            resolve: null,
            reject: null,
            person: null,
            condition: null
        }),
        computed: {
            metaProps() {
                return Object.keys(this.condition.meta || {}).filter((key) => {
                    if (key === 'numberOfSkinCancers') {
                        return false; // deprecated
                    }
                    if (key === 'a1c') {
                        return false; // deprecated
                    }
                    if (key === 'a1cTestYear') {
                        return false; // deprecated
                    }
                    if (key === 'numberOfSkinCancersMelanoma') {
                        return this.isCancerTypeSelected(this.condition, 'melanoma');
                    }
                    if (key === 'numberOfSkinCancersNonMelanoma') {
                        return this.isCancerTypeSelected(this.condition, 'non_melanoma');
                    }
                    return true;
                });
            },
            isOtherCondition() {
                return /^other/.test(this.condition?.id);
            },
            isValid() {
                return (isNumber(this.condition.age) || this.condition.ageUnknown || this.condition.skipAge) && this.metaProps.every((prop) => !!this.condition.meta[prop] || includes(optionalMetaProps, prop));
            },
            age() {
                if (this.person?.ageAtDeath) {
                    return this.person.ageAtDeath;
                } else if (this.person?.birthDate) {
                    return moment().diff(moment(this.person.birthDate), 'years');
                }
                return this.person?.age || 100;
            }
        },
        watch: {
            condition: {
                handler() {
                    if (isNumber(this.condition?.age)) {
                        this.condition.ageUnknown = false;
                    }
                    if (this.isOtherCondition) {
                        this.setSearchTextDebounced(this.condition?.meta?.other || '');
                    }
                },
                deep: true
            },
            dialog(val) {
                if (!val) {
                    this.searchText = '';
                }
            }
        },
        methods: {
            setSearchTextDebounced: debounce(function (searchText) {
                this.searchText = searchText;
            }, 500),
            ageUnknownChange() {
                if (this.condition?.ageUnknown) {
                    this.condition.age = null;
                }
            },
            show(person, condition) {
                this.alwaysShowOtherConditions = false;
                this.useFuzzy = true;
                this.dialog = true;
                this.person = person;
                this.condition = condition;
                this.causeOfDeath = `condition:${this.condition.id}` === this.person.causeOfDeath;
                return new Promise((resolve, reject) => {
                    this.resolve = resolve;
                    this.reject = reject;
                });
            },
            add(person, condition) {
                this.mode = 'add';
                return this.show(person, condition);
            },
            update(person, condition) {
                this.mode = 'update';
                return this.show(person, condition);
            },
            ok() {
                this.resolve({ condition: this.condition, causeOfDeath: this.causeOfDeath });
                this.dialog = false;
            },
            cancel() {
                this.reject();
                this.dialog = false;
            }
        }
    };
</script>
