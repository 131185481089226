<template>
    <v-dialog v-model="dialog" :max-width="800" eager scrollable id="tour-conditions-dialog">
        <v-card class="d-flex flex-column">
            <v-toolbar dense dark color="primary" class="flex-grow-0">
                <v-toolbar-title>
                    <div class="d-flex align-center">
                        <v-icon small left>fas fa-first-aid fa-fw</v-icon>
                        {{ $t('labels.addConditions') }}
                    </div>
                </v-toolbar-title>
                <v-spacer></v-spacer>
                <v-btn small text outlined class="btn-outline-solid-white" @click="hide()" id="tour-conditions-done">
                    <v-icon small left>fas fa-check</v-icon>
                    {{ $t('labels.doneAddingConditions') }}
                </v-btn>
            </v-toolbar>
            <div class="my-2 px-4">
                <div id="tour-conditions-search">
                    <v-text-field dense outlined hide-details clearable autofocus clear-icon="fas fa-times" v-model="searchText" @input="search" prepend-inner-icon="fas fa-search" :placeholder="$t('common.search_for_condition')">
                        <template #append-outer>
                            <v-btn-toggle active-class="primary white--text" :value="useFuzzy" @change="useFuzzy = !useFuzzy" mandatory>
                                <v-btn small :value="true">{{ $t('common.enhanced') }}</v-btn>
                                <v-btn small :value="false">{{ $t('common.basic') }}</v-btn>
                            </v-btn-toggle>
                        </template>
                    </v-text-field>
                </div>
                <v-row no-gutters align="center" class="my-2 mx-6">
                    <v-col class="ml-6">
                        <v-divider class="primary"></v-divider>
                    </v-col>
                    <v-col cols="auto" class="mx-6">
                        <span class="body-2 primary--text" @click.shift="showScores = !showScores">{{ $t('common.or') }}</span>
                    </v-col>
                    <v-col class="mr-6">
                        <v-divider class="primary"></v-divider>
                    </v-col>
                </v-row>
                <v-card outlined class="my-2 primary--border" id="tour-conditions-filter">
                    <div class="py-2 px-3 primary--text">
                        <v-icon small left color="primary">fas fa-filter fa-fw</v-icon>
                        {{ $t('common.filter_by_category') }}
                    </div>
                    <v-divider class="primary"></v-divider>
                    <div class="pa-2">
                        <v-btn
                            small
                            outlined
                            rounded
                            color="primary"
                            class="filter-button ma-1"
                            v-for="(category, index) in categories"
                            :key="index"
                            :input-value="selectedCategoryIndex === index"
                            :disabled="!countByCategory(category)"
                            @click="filter(index)"
                        >
                            <div class="d-flex align-center">
                                <v-icon small left v-if="categoryIcon(category)">fas {{ categoryIcon(category) }} fa-fw</v-icon>
                                {{ $t(`categories.${category}`) }}
                                <div class="text-caption hint-text ml-1">{{ countByCategory(category) }}</div>
                            </div>
                        </v-btn>
                    </div>
                </v-card>
            </div>
            <div class="d-flex align-center justify-space-between mx-6">
                <div class="text-subtitle-1 hint-text">{{ $t('hints.addConditions') }}</div>
                <div class="text-caption hint-text">{{ $tc('common.results', filteredConditions.length) }}</div>
            </div>
            <v-divider></v-divider>
            <v-card-text class="pa-0" style="height: 416px" v-if="person && filteredConditions && filteredConditions.length" id="tour-conditions-list">
                <ConditionsList
                    :person="person"
                    :familyConditions="familyConditions"
                    :filteredConditions="filteredConditions"
                    :showScores="showScores"
                    @click="
                        (cond) =>
                            cond.skipQuestions &&
                            $root.$confirmationDialog.show({ title: $t('prompts.conditionAdded.title'), message: $t('prompts.conditionAdded.message', { condition: $t(`conditions.${cond.id}.name`) }), showCancel: false })
                    "
                ></ConditionsList>
            </v-card-text>
        </v-card>
    </v-dialog>
</template>

<script>
    import { ConditionsMixin } from '@/components/ConditionsMixin';
    import { ConditionsSearchMixin } from '@/components/ConditionsSearchMixin';
    import ConditionsList from '@/components/ConditionsList';
    import { categories } from '@/common';

    export default {
        mixins: [ConditionsMixin, ConditionsSearchMixin],
        components: { ConditionsList },
        data: () => ({
            dialog: false,
            person: null,
            showScores: false,
            categories
        }),
        methods: {
            show({ searchText = '', selectedCategoryIndex = null, person }) {
                this.searchText = searchText;
                this.selectedCategoryIndex = selectedCategoryIndex;
                this.person = person;
                this.dialog = true;
            },
            hide() {
                this.dialog = false;
            }
        }
    };
</script>

<style lang="scss" scoped>
    ::v-deep .filter-button {
        text-transform: none;
    }
</style>
